<template>
  <div class="auditing-box">
    <van-nav-bar :title="title" />
    <div class="auditing-con">
      <div class="auditing">
        <img src="../../assets/positionMgt/auditing.png" alt />
        <p>{{ title }}</p>
      </div>
      <div class="auditing-list">
        <div class="list-box" v-if="userInfo">
          <van-list>
            <van-cell title="姓名">
              {{
              userInfo.userSelfhelpVO.userName || '-'
              }}
            </van-cell>
          </van-list>
          <van-list>
            <van-cell title="性别">
              {{
              userInfo.userSelfhelpVO.gender === 'MALE' ? '男' : '女' || '-'
              }}
            </van-cell>
          </van-list>
          <van-list>
            <van-cell title="公司名称">
              {{
              userInfo.companyVO.nameCn || '-'
              }}
            </van-cell>
          </van-list>
          <van-list>
            <van-cell title="邮箱地址（企业邮箱）">
              {{
              userInfo.userSelfhelpVO.email || '-'
              }}
            </van-cell>
          </van-list>
          <van-list>
            <van-cell title="注册用户所在部门">
              {{
              userInfo.companyDeptVO.deptName || '-'
              }}
            </van-cell>
          </van-list>
          <div class="upload-box">
            <p>认证图片信息</p>
            <van-uploader v-model="fileList" :deletable="false"></van-uploader>
          </div>
          <div class="btn-box">
            <van-button plain round block type="info" @click="goLogin">切换账号</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'Auditing',
  data() {
    return {
      title: '',
      userInfo: null,
      fileList: [],
    };
  },
  async mounted() {
    let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
    if (back.code === 200) {
      localStorage.setItem('userInfo', JSON.stringify(back.data));
      let urlPath = null;
      switch (back.data.approveCodeEnum) {
        case 'UN_LINK':
          urlPath = '/noLegalize';
          break;
        case 'APPROVE_FAIL':
          urlPath = '/noPassAudit';
          break;
        case 'APPROVE_PASS':
          urlPath = '/passAudit';
          break;
        case 'PROCESS_DONE':
          urlPath = '/positionMgt/index';
          break;
        case 'COMPANY_STOP':
          urlPath = '/login';
          localStorage.setItem('userInfo', '');
          localStorage.setItem('accessToken', '');
          this.$toast('公司被禁用');
          break;
        case 'STOP':
          urlPath = '/login';
          localStorage.setItem('userInfo', '');
          localStorage.setItem('accessToken', '');
          this.$toast('账号禁用');
          break;
      }
      // 登录成功后跳转到主页
      if (back.data.approveCodeEnum === 'PROCESS_DONE') {
        this.SET_TABBARACTIVE(5);
      }
      this.$router.push({ path: urlPath });
      this.userInfo = back.data;
      this.fileList = [
        { url: this.userInfo.selfhelpCompanyApproveVO.proveEnclosure },
      ];
      this.title =
        back.data.approveCodeEnum === 'SECONED_WAITTING'
          ? '认证待审核'
          : '认证待审核';
    }
  },
  methods: {
    ...mapMutations('app', ['SET_TABBARACTIVE']),
    onClickLeft() {
      this.$router.go(-1);
    },
    //切换登陆
    goLogin() {
      localStorage.setItem('userInfo', '');
      localStorage.setItem('accessToken', '');
      this.$router.push('/login')
    },
  },
};
</script>

<style lang="scss" scoped>
.auditing-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .auditing-con {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    .auditing-list {
      width: 100%;
    }
    .auditing {
      margin-top: 80px;
      width: 276px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 90px;
        width: 90px;
      }
      p {
        margin-top: 8px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .van-cell {
      flex: 1;
    }
    .van-cell::after {
      border-bottom: none;
    }
    .van-cell--required::before {
      display: none;
    }
    .list-box {
      padding: 0 16px;
      margin: 20px 0;
      p {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 15px;
      }
      .filed-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ebedf0;
        img {
          width: 77px;
          height: 23px;
        }
      }
      ::v-deep .van-uploader__upload {
        display: none;
      }
      ::v-deep .van-field__control {
        text-align: right;
      }
      .van-uploader {
        width: 100%;
      }
      ::v-deep .van-uploader__input-wrapper {
        width: 100%;
      }

      > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        i {
          color: #f44336;
          font-style: normal;
        }
      }
    }
    .upload-box {
      margin-top: 20px;
    }
  }
  .list-box .van-list {
    min-height: 60px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    .van-cell {
      padding: 0;
      align-items: center;
    }
    .van-cell__title span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .van-cell__value {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
    }
  }
  .btn-box {
    margin-top: 40px;
    padding: 0 16px;
  }
}
</style>
